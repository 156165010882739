import React from "react"
import Layout from "../../../components/Layout/Layout"
import SuccessAnimation from "../../../components/SuccessAnimation/SuccessAnimation"

import "../../../styles/success.scss"

export default function Success() {
  return (
    <Layout fullHeight>
      <div className="job-application-success">
        <SuccessAnimation />
        <h1>Application submitted.</h1>
        <p>
          Thank you for applying for this position. We'll get back to you as
          soon as possible.
        </p>
      </div>
    </Layout>
  )
}
